module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ;  response.branches.forEach(function(branch) {
    ; __append("\n  ")
    ;  var item=branch.items[0] 
    ; __append("\n    ")
    ;  if (!item) { return; }
    ; __append("\n\n      <div class=\"mt-2 branch\" id=\"current-menu-item-id-")
    ; __append(escapeFn(item.id))
    ; __append("\">\n        <div class=\"row\">\n\n          <div class=\"col-2\">\n            <div class=\"square-image-inherited-size\">\n              <img src=")
    ; __append(escapeFn(item.image_url ))
    ; __append(" width=\"50\" class=\"discover-thumb\"/>\n            </div>\n          </div>\n\n          <div class=\"col align-self-center item-info\">\n            <p>\n              ")
    ; __append(escapeFn( branch.shop_name ))
    ; __append("\n            </p>\n            <p>\n              ")
    ; __append(escapeFn( item.name ))
    ; __append("\n            </p>\n            <small class=\"p-0 m-0 small-hint\">\n              <i class=\"fa fa-clock-o\" aria-hidden=\"true\"></i>\n              ")
    ; __append(escapeFn( branch.minsAway ))
    ; __append(" min away\n                ")
    ; __append(escapeFn( branch.name ))
    ; __append("\n            </small>\n          </div>\n\n          <div class=\"col-4\">\n            <a href=")
    ; __append(escapeFn(`/moderator/shop/${branch.shop_id}/branches/${branch.id}/menu_items`))
    ; __append(" class=\"btn btn-orange\n              strong-rounded-corners\">View Menu </a>\n            <a href=\"/admins/branches/")
    ; __append(escapeFn( branch.id ))
    ; __append("/menu_items/")
    ; __append(escapeFn( item.id ))
    ; __append("/tags/")
    ; __append(escapeFn( current_tag_id ))
    ; __append("/edit_discover_menu_items\"\n              data-remote=\"true\" class=\"btn btn-success strong-rounded-corners edit-menu-btn\">Edit</a>\n          </div>\n        </div>\n\n      </div>\n\n      ")
    ;  var discountTag=item.tag !='' 
    ; __append("\n        ")
    ;  if(item.tag !='' ) { 
    ; __append("\n          <div class=\"row mt-2 justify-content-center\">\n            <div class=\"col\">\n              <div class=\"item-img-desc\">\n                <p class=\"discount-label\">\n                  ")
    ; __append(escapeFn( item.tag ))
    ; __append("\n                </p>\n              </div>\n            </div>\n          </div>\n          ")
    ;  } 
    ; __append("\n            </div>\n            <hr />\n            ")
    ;  }); 
  }
  return __output;

}