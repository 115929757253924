
import { CreditCardView } from "../utilits/credit_card_view";
import { ApplePaySession } from "../utilits/apple_pay_session";
import { Indoor } from "../utilits/indoor";
import { CategoryScale } from "chart.js";

export class PaymentMethod {
  static applePayButton = 'apple-pay-button'
  static placeOrderButton = '#place-order-button'
  static paymentMethodsCon = '#payment-methods';
  static paymentMethodRadioButtons = '.golden-radio';

  // static showPaymentMethod(instance){
  //   let paymentMethod = $(instance.ui).data('paymentMethodObject');
  //   if( $(instance.ui).data('showPaymentMethod') && instance.webSupported()) { $(instance.ui).show(); }
  //   if( $(instance.ui).data('defualtPaymentMethod') && instance.webSupported() ) { instance.select(); } 
  // }
  
  static handleDisplay(_this){
    _this.paymentMethod = $(_this.ui).data('paymentMethodObject');
    console.log(_this.paymentMethod);
    (!_this.webSupported()) ? $(_this.ui).hide() : $(_this.ui).show();
    if(_this.paymentMethod.selected && _this.webSupported()){ _this.select();}
  
    if( PaymentMethod.defaultPaymentMethodNotSupported() ){
      O.on('PaymentMethod.Cash.created', (instance) => {  instance.select();});
    }
  }

  static defaultPaymentMethodNotSupported(){    
    return $(PaymentMethod.paymentMethodsCon).find(`${this.paymentMethodRadioButtons}:checked`).length < 1 
  }
}

PaymentMethod.Cash = class  {

  static radioButton = '#cash-label'
  radioButton = '#cash-label'
  cashTextField = '#cash-text-field'

  constructor(ui) {
    this.ui = ui;
    PaymentMethod.handleDisplay(this);
  }


  wasSelected() { 
    $(PaymentMethod.placeOrderButton).show()
    $(PaymentMethod.applePayButton).hide()
  }


  static get() { return this.pool[0] }
  getCashAmount() { return $(this.ui).find(this.cashTextField).val() }
  
  webSupported(){ return true; }

  select() { $(this.radioButton).trigger('click'); } 
}

PaymentMethod.CreditCard = class extends CreditCardView {
  constructor(ui) {
    console.log('CreditCard');
    console.log(ui);
    super(ui);
    console.log(this);
    PaymentMethod.handleDisplay(this);
  }

  wasSelected() { 
    $(PaymentMethod.placeOrderButton).show()
    $(PaymentMethod.applePayButton).hide()
  }

  webSupported(){ return true; }

  select() {
    $(this.radioButton).trigger('click');
  }
}

PaymentMethod.ApplePay = class {
  radioButton = '#ap-label'
  delegate = {
    paymentWasAuthorized: () => {}
  }

  constructor(ui) {
    this.ui = ui
    const options = {
      country_code: 'AE',
      currency_code: Indoor.cart.currency(), 
      payment_amount: Indoor.cart.grandTotal(),
      onPaymentAuthorized: (applePayToken) => { 
        $('.results').append('<li>after authorized callback with apple pay token</li>');
        this.onPaymentAuthorized(JSON.stringify(applePayToken)) 
      },
      onPaymentIsNotAvailable: () => { this.remove() }
    }

    $(PaymentMethod.applePayButton).on('click', () => {
      if(!Packs.application.Indoor.placeOrderView.pool[0].isValidSession()) {
        return;
      }
      new ApplePaySession(options).createSession();  
    });

    PaymentMethod.handleDisplay(this);
  }


  remove() {
    $(this.ui).hide()
  }

  onPaymentAuthorized(applePayToken){ 
    this.delegate.paymentWasAuthorized(applePayToken)
  }

  wasSelected() { 
    $(PaymentMethod.placeOrderButton).hide()
    $(PaymentMethod.applePayButton).show()
  }

  setDelegate(delegate) { this.delegate = delegate }

  webSupported(){ 
    return(window.ApplePaySession && window.ApplePaySession.canMakePayments() &&  this.paymentMethod.enabled == true);
  }

  select() {
    $(this.radioButton).trigger('click');
  }
}
