import RecordRTC from "recordrtc";

const itemsResultView = require("../templates/item_search.ejs")

export class AiChat {

  constructor(ui) {
    this.ui = ui;

    this.recorder = undefined
    this.mediaStream = undefined
    this.mediaRecorder = undefined
    this.audioChunks = []
    this.chattingUrl = this.ui.dataset.chattingUrl;
    this.lng = 55.28233039805896
    this.lat = 25.214064297239194

    O.on('MapView.clicked', (data) => {
      this.lat = data.event.latLng.lat()
      this.lng = data.event.latLng.lng()
      this.location = `${this.lat},${this.lng}`
    });

    this.loadListeners()
  }

  loadListeners() {
    this.recordButtonListener()
    this.sendButtonListener();
  }

  sendButtonListener() {
    $('#send-button').click(function () {
      Packs.application.globalFun.showLoadingWheel();
      var messageText = $('#message-input').val().trim();

      if (messageText) {
        var messageHtml = `
              <div class="message sent">
                  <div class="message-bubble">${messageText}</div>
              </div>
          `;
        $('#chat-messages').append(messageHtml);
        $('#chat-messages').scrollTop($('#chat-messages')[0].scrollHeight);
        $('#message-input').val('');

        $.ajax({
          url: this.chattingUrl,
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({ message: messageText }),
          success: function (response) {
            var responseText = response.message;
            var responseHtml = `
                <div class="message received">
                  <div class="message-bubble">${responseText}</div>
                </div>`
            $('#chat-messages').append(responseHtml);
            $('#chat-messages').scrollTop($('#chat-messages')[0].scrollHeight);
            Packs.application.globalFun.hideLoadingWheel();
          },
          error: function (jqXHR, textStatus, errorThrown) {
            Packs.application.globalFun.hideLoadingWheel();
          }
        });
      }
    });

    $('#message-input').keypress(function (e) {
      if (e.which === 13) {
        $('#send-button').click();
      }
    });
  }

  recordButtonListener() {

    $('#record-button').click(() => {
      if (!this.mediaRecorder || this.mediaRecorder.state === "inactive") {

        navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
          this.recorder = RecordRTC(stream, {
            type: 'audio',
            mimeType: 'audio/m4a',
          });

          this.mediaStream = stream;
          this.recorder.startRecording();

          this.mediaRecorder = new MediaRecorder(stream);
          this.mediaRecorder.start();
          $('#recording-indicator').show();

          this.mediaRecorder.ondataavailable = event => {
            this.audioChunks.push(event.data);
          };

          $(this).text('Stop Recording');
        }).catch(error => {
          console.error("Error accessing the microphone:", error);
          alert("Could not access the microphone. Please check your permissions.");
        });
      } else {

        this.recorder.stopRecording(() => {
          const audioBlob = this.recorder.getBlob();
          // const audioUrl = URL.createObjectURL(audioBlob);
          this.sendAudio(audioBlob)
          if (this.mediaStream) { this.mediaStream.getTracks().forEach(track => track.stop()); }
        });
        $('#recording-indicator').hide();
        this.mediaRecorder.stop();
        $(this).text('🎤 Record');
      }
    });
  }

  sendAudio(audioBlob) {
    const formData = new FormData()
    formData.append('audio', audioBlob, `audio_${Date.now()}_${Math.floor(Math.random() * 10000)}.m4a`)
    formData.append('latitude', this.lat)
    formData.append('longitude', this.lng)
    Packs.application.globalFun.showLoadingWheel()

    $.ajax({
      url: this.chattingUrl,
      method: 'POST',
      processData: false,
      contentType: false,
      data: formData,
      success: (response) => {
        $('#itemsResultModal .modal-body').empty()
        response.items.forEach(item => {
          // const menuItem = `
          //           <div class="menu-item">
          //               <img src="${item.image_url}" alt="${item.name}" class="img-fluid" />
          //               <p>${item.name}</p>
          //           </div>
          //       `;

          // $('#itemsResultModal .modal-body').append(menuItem)
          item.branch.minsAway = Math.ceil(this.calculateDistance(new google.maps.LatLng(parseFloat(this.lat),
            parseFloat(this.lng)), new google.maps.LatLng(parseFloat(item.branch.latitude),
              parseFloat(item.branch.longitude))))
        })

        // $('#items').append(itemsResultView({ response: response }))
        $('#itemsResultModal .modal-body').append(itemsResultView({ response: response }))
        $('#itemsResultModal').modal('show');
        Packs.application.globalFun.hideLoadingWheel();
      },
      error: function (jqXHR, textStatus, errorThrown) {
        Packs.application.globalFun.hideLoadingWheel();
        console.error("Error sending audio:", errorThrown);
      }
    })
  }

  calculateDistance(from_location, to_location) {
    var distanceMeter = google.maps.geometry.spherical.computeDistanceBetween(from_location, to_location);
    var distanceKiloMeter = (distanceMeter / 1000);
    var timeDiff = ((distanceKiloMeter * 60) / 40)
    return (timeDiff < 2) ? 2 : timeDiff
  }
}